.project {
    &:not(:first-child) {
        margin-top: $spacing-xl;
    }

    .preview {
        position: relative;

        width: 100%;
        margin-top: $spacing-m;

        &.__inner {
            width: 100%;
        }
    }

    .description {
        margin: $spacing-m 0;
    }

    .links {
        li {
            display: inline-block;

            margin-right: .1em;
            margin-bottom: .6em;
        }
    }

    .notice {
        font-size: .6em;
        font-weight: 300;

        padding: .4em;

        text-align: center;

        color: $color-warning;
        background-color: rgb(234 161 0 / 30%);
    }

    img {
        display: block;

        width: 100%;

        object-fit: cover;

        &.mobile {
            width: 100%;
            height: 100%;

            background-color: lightgray;
        }
    }

    video {
        position: relative;

        display: block;

        width: 100%;

        object-fit: cover;
    }
}

/* for blink/webkit */
details summary::-webkit-details-marker {
    display: none;
}

/* for firefox */
details > summary:first-of-type {
    list-style: none;
}

summary {
    font-weight: 500;

    margin-top: $spacing-xl;

    &:hover {
        @extend .shake;
    }
}

[open] summary {
    display: none;
}

.preview:after {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    content: 'loading...';
    transform: translate(-50%, -50%);

    color: $color-gray;
    background-color: rgba($color-gray, .2);
}

.dmwtf {
    .preview {
        &:after {
            background-color: transparent;
        }
    }
    img {
        border-radius: 1vw;
    }
}

.friskvardsbidrag, .dmwtf {
    img {
        box-shadow: $shadow;
    }
}

.freeze {
    position: relative;

    .preview:after {
        display: none;
    }

    .window {
        position: relative;

        overflow: hidden;

        width: 100%;
    }

    .window, video {
        aspect-ratio: calc(16 / 9);
    }

    img.mobile {
        object-fit: cover;
    }

    video {
        width: 100%;
        min-width: 100%;

        background-color: rgba($color-gray, .2);

        object-fit: cover;
    }

    span.link {
        color: $color-black;
    }

    .clock {
        font-family: 'Rubik', sans-serif;
        font-size: 6vmax;
        font-weight: 500;
        line-height: 1;

        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;

        padding: .2vmax 1vmax;

        transform: translate(-50%, -50%);
        text-transform: uppercase;
        pointer-events: none;

        color: white;
        border-radius: .3vmax;
        box-shadow: 0 0 0 .8vmax #fff;
    }
}
