header {
    display: flex;
    align-items: center;

    padding: $spacing-xl 0;
    h1 {
        position: relative;
    }
    h1 span {
        position: relative;

        display: inline-block;

        color: $color-primary;

        @extend .shake;
    }
}
