a, .link {
    display: inline-block;

    white-space: nowrap;

    color: $color-gray;
    &:hover {
        text-decoration: none;
    }
}

.link {
    cursor: pointer;
    text-decoration: underline;
}

p .link, p a {
    color: $color-black;
}


.favicon {
    display: inline-block;

    width: 1em;
    height: 1em;
    margin-right: .2em;

    content: '';
    transform: translate(0em, .17em) scale(.9);

    background-repeat: no-repeat;
    background-size: contain;
}

a[href*='9to5google'] {
    &:before {
        background-image: url('../static/favicons/9to5google.png');

        @extend .favicon;
    }
}

a[href*='reddit'] {
    &:before {
        background-image: url('../static/favicons/reddit.png');

        @extend .favicon;
    }
}

a[href*='news.ycombinator'] {
    &:before {
        background-image: url('../static/favicons/hackernews.png');

        @extend .favicon;
    }
}

a[href*='feber'] {
    &:before {
        background-image: url('../static/favicons/feber.png');

        @extend .favicon;
    }
}

a[href*='gizmodo'] {
    &:before {
        background-image: url('../static/favicons/gizmodo.png');

        @extend .favicon;
    }
}
a[href*='motherboard'] {
    &:before {
        background-image: url('../static/favicons/motherboard.png');

        @extend .favicon;
    }
}
a[href*='producthunt'] {
    &:before {
        background-image: url('../static/favicons/producthunt.svg');

        @extend .favicon;
    }
}

a[href*='inspiring.online'] {
    &:before {
        background-image: url('../static/favicons/inspiringonline.jpg');

        @extend .favicon;
    }
}

a[href*='softpedia'] {
    &:before {
        background-image: url('../static/favicons/softpedia.png');

        @extend .favicon;
    }
}

a[href*='webaudioweekly'] {
    &:before {
        background-image: url('../static/favicons/webaudio.jpg');

        @extend .favicon;
    }
}

a[href*='youtube.com'] {
    &:before {
        background-image: url('../static/favicons/youtube.png');

        @extend .favicon;
    }
}

a[href*='vimeo'] {
    &:before {
        background-image: url('../static/favicons/vimeo.png');

        @extend .favicon;
    }
}
