/*------------------------------------*\
    $CONFIG
\*------------------------------------*/
@import 'config/variables';

/*------------------------------------*\
    $BASE
\*------------------------------------*/
@import 'abstracts/mixins';
@import 'abstracts/base';
@import 'abstracts/type';
@import 'abstracts/animations';

/*------------------------------------*\
    $COMPONENTS
\*------------------------------------*/

@import 'components/buttons';
@import 'components/links';

/*------------------------------------*\
    $SECTIONS
\*------------------------------------*/
@import 'sections/header';
@import 'sections/project';
@import 'sections/footer';
