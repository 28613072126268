/*------------------------------------*\
    $BASE DEFAULTS
\*------------------------------------*/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: clamp(10px, 2.9vw, 30px);

    -webkit-text-size-adjust: 100%;
}

img, svg {
    vertical-align: top;
}

button, input, select, textarea {
    font-family: inherit;
}

body {
    font-family: $font--primary, sans-serif;

    background-color: white;
}

::selection {
    background: rgba($color-primary, .3);
}

.wrapper {
    max-width: 1040px;
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
}

header, main, details, footer > div {
    @extend .wrapper;
}

.hidden {
    display: none!important;
}
