/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '+'Available breakpoints are: #{map-keys($breakpoints)}.';
    }
}


/* ============================================
  Center selfe in parent
*/

@mixin center-selfe() {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}
