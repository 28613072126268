/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

// Colors
$color-primary: #01d06c; // #D01902;
$color-gray: #b9b9b9;
$color-bege: #eee6b6;
$color-black: #000;
$color-danger: #920031;
$color-warning: #b07900;
// Shaodws
$shadow: 0 2px 70px 0 rgba(34, 49, 102, .1);
$spacing-xl: 7rem;
$spacing-l: 2.6rem;
$spacing-m: 1.3rem;
// Typography
$font--primary: 'Space Grotesk',
sans-serif;
// Breakpoints
$breakpoints: ( 'small': ( min-width: 600px), 'medium': ( min-width: 1000px), 'large': ( min-width: 1400px)) !default;
// Transitions
$default-transition-speed: 300ms;
// Z-index
$z-alpha: 7;
$z-beta: 6;
$z-gamma: 5;
$z-delta: 4;
$z-epsilon: 3;
$z-zeta: 2;
$z-milli: 1;
$z-base: 0;
$z-hidden: -1;
$z-giga: 9999;
$z-mega: 110;
$z-kilo: 109;
