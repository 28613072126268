/*------------------------------------*\
    $TYPE
\*------------------------------------*/

@font-face {
    font-family: 'Space Grotesk';
    font-weight: 800;

    src: url('../static/fonts/SpaceGrotesk-Bold.woff2') format('woff2'), url('../static/fonts/SpaceGrotesk-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Space Grotesk';
    font-weight: 500;

    src: url('../static/fonts/SpaceGrotesk-Medium.woff2') format('woff2'), url('../static/fonts/SpaceGrotesk-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Space Grotesk';
    font-weight: 400;

    src: url('../static/fonts/SpaceGrotesk-Regular.woff2') format('woff2'), url('../static/fonts/SpaceGrotesk-Regular.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css?family=Rubik:500');
body {
    font-size: clamp(18px, 4vw, 30px);
}

h1 {
    font-size: clamp(25px, 8vw, 93px);
    font-weight: 800;

    position: relative;
    z-index: 100;

    @include respond-to('large') {
    }
}

h2 {
    font-size: 20px; // for initial sizing only
    line-height: 1;

    width: 100%;
    padding: .25em;

    text-align: center;
    text-transform: uppercase;

    color: white;
    background-color: $color-black;

    @include respond-to('small') {
        padding: .6em 1em;
    }
}

h3 {
    font-size: clamp(28px, 4.9vw, 52px);
    font-weight: 400;
}

p {
    font-weight: 500;
}
